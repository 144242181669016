<template>
  <el-row>
    <el-col :span="24" style="padding: 0 25%;">
      <div class="top_space">配件</div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="AIO-5" name="first">
          <div class="content">
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box1"></div>
              <p>内置胎压传感器</p>
              <p>￥395</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box2"></div>
              <p>外置胎压传感器</p>
              <p>￥375</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box3"></div>
              <p>无线OBD模块</p>
              <p>￥399</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box4"></div>
              <p>17mm球头后视镜管夹</p>
              <p>￥120</p>
            </div>
          </div>
          <div class="content">
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box5"></div>
              <p>内置胎压传感器</p>
              <p>￥395</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box6"></div>
              <p>外置胎压传感器</p>
              <p>￥375</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box7"></div>
              <p>无线OBD模块</p>
              <p>￥399</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box8"></div>
              <p>17mm球头后视镜管夹</p>
              <p>￥120</p>
            </div>
          </div>
          <div class="content">
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box9"></div>
              <p>内置胎压传感器</p>
              <p>￥395</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box10"></div>
              <p>外置胎压传感器</p>
              <p>￥375</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box11"></div>
              <p>无线OBD模块</p>
              <p>￥399</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="box12"></div>
              <p>17mm球头后视镜管夹</p>
              <p>￥120</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="CG-2" name="second">
          <div class="content">
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box1"></div>
              <p>内置胎压传感器</p>
              <p>￥395</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box2"></div>
              <p>外置胎压传感器</p>
              <p>￥375</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box3"></div>
              <p>无线OBD模块</p>
              <p>￥399</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box4"></div>
              <p>17mm球头后视镜管夹</p>
              <p>￥120</p>
            </div>
          </div>
          <div class="content">
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box5"></div>
              <p>内置胎压传感器</p>
              <p>￥395</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box6"></div>
              <p>外置胎压传感器</p>
              <p>￥375</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="XR-3" name="third">
          <div class="content">
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box1"></div>
              <p>内置胎压传感器</p>
              <p>￥395</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box2"></div>
              <p>外置胎压传感器</p>
              <p>￥375</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box3"></div>
              <p>无线OBD模块</p>
              <p>￥399</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box4"></div>
              <p>17mm球头后视镜管夹</p>
              <p>￥120</p>
            </div>
          </div>
          <div class="content">
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box5"></div>
              <p>内置胎压传感器</p>
              <p>￥395</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box6"></div>
              <p>外置胎压传感器</p>
              <p>￥375</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="G3" name="fourth">
          <div class="content">
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box1"></div>
              <p>内置胎压传感器</p>
              <p>￥395</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box2"></div>
              <p>外置胎压传感器</p>
              <p>￥375</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box3"></div>
              <p>无线OBD模块</p>
              <p>￥399</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box4"></div>
              <p>17mm球头后视镜管夹</p>
              <p>￥120</p>
            </div>
          </div>
          <div class="content">
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box5"></div>
              <p>内置胎压传感器</p>
              <p>￥395</p>
            </div>
            <div class="content_item">
              <div style="width: 140px; height: 140px;" class="tab2_box6"></div>
              <p>外置胎压传感器</p>
              <p>￥375</p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      activeName: 'first'
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  padding: 0 200px;
  background-color: #f8f8f8;
}
.top_space {
  width: 100%;
  height: 160px;
  line-height: 160px;
  font-family: 500;
  font-size: 40px;
  color: #111;
}
.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 150px 0;
  .content_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    font-size: 15px;
  }
}
.box1 {
  background: url('../../../public/images/parts/tab1/1.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.box2 {
  background: url('../../../public/images/parts/tab1/2.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.box3 {
  background: url('../../../public/images/parts/tab1/3.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.box4 {
  background: url('../../../public/images/parts/tab1/4.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.box5 {
  background: url('../../../public/images/parts/tab1/5.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.box6 {
  background: url('../../../public/images/parts/tab1/6.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.box7 {
  background: url('../../../public/images/parts/tab1/7.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.box8 {
  background: url('../../../public/images/parts/tab1/8.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.box9 {
  background: url('../../../public/images/parts/tab1/9.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.box10 {
  background: url('../../../public/images/parts/tab1/10.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.box11 {
  background: url('../../../public/images/parts/tab1/11.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.box12 {
  background: url('../../../public/images/parts/tab1/12.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.tab2_box1 {
  background: url('../../../public/images/parts/tab2/1.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.tab2_box2 {
  background: url('../../../public/images/parts/tab2/2.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.tab2_box3 {
  background: url('../../../public/images/parts/tab2/3.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.tab2_box4 {
  background: url('../../../public/images/parts/tab2/4.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.tab2_box5 {
  background: url('../../../public/images/parts/tab2/5.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
.tab2_box6 {
  background: url('../../../public/images/parts/tab2/6.png') no-repeat;
  background-size: 100% 100%;
  // border: 2px dashed #111;
  margin-bottom: 15px;
}
</style>